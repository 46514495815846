import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [];

export const dictionary = {
		"/(overview)": [6,[2]],
		"/(overview)/archive": [7,[2]],
		"/(overview)/championships": [8,[2]],
		"/(overview)/championships/[championshipid=integer]": [9,[2]],
		"/(overview)/documents": [10,[2]],
		"/(overview)/documents/[documentid=integer]": [11,[2]],
		"/livetiming/[eventid=integer]": [15,[3]],
		"/livetiming/[eventid=integer]/documents": [16,[3]],
		"/livetiming/[eventid=integer]/documents/[documentid=integer]": [17,[3]],
		"/livetiming/[eventid=integer]/race/[[raceid=integer]]": [18,[3,4]],
		"/livetiming/[eventid=integer]/rider": [19,[3]],
		"/livetiming/[eventid=integer]/rider/[riderid=integer]": [20,[3]],
		"/livetiming/[eventid=integer]/tracker": [22,[3]],
		"/livetiming/[eventid=integer]/track": [21,[3]],
		"/livetiming/[eventid=integer]/tv": [23,[5]],
		"/livetiming/[eventid=integer]/tv/scoreboard": [24,[5]],
		"/(overview)/rider": [12,[2]],
		"/(overview)/rider/[riderid=integer]": [13,[2]],
		"/(overview)/settings": [14,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';